import React from 'react'
import styled from 'styled-components'

import { toDate } from '../../../utils/time'
import { Render } from '../../../utils/render'

const SimpleCard = ({ image, header, content, date }) => {
	return (
		<CardWrapper>
			{image && (
				<ImageWrapper>
					<Image src={image} />
				</ImageWrapper>
			)}
			<TextWrapper>
				<TitleAndContentWrapper>
					<Header>{header}</Header>
					<Content>
						<Render html={content} />
					</Content>
				</TitleAndContentWrapper>
				<DateWrapper>
					<Date>{toDate(date)}</Date>
				</DateWrapper>
			</TextWrapper>
		</CardWrapper>
	)
}

const CardWrapper = styled.div`
	width: 340px;
	border: 1px solid gray;
	height: 400px;
	overflow: hidden;
	position: relative;
`

const ImageWrapper = styled.div`
	height: 200px;
`

const Image = styled.img`
	max-width: 100%;
	height: 200px;
`

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	height: 160px;
`

const TitleAndContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
const Header = styled.p`
	font-size: 1.5rem;
	height: 2rem;
	margin: 0;
	color: #333333;

	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`
const Content = styled.section`
	color: #333333;
	margin: 0;

	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`

const DateWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
const Date = styled.span`
	color: #999999;
`

export default SimpleCard
