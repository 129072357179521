import React, { Component } from 'react'
import Select from 'react-select'
import { withRouter } from 'react-router-dom'

const customStyles = ({ top }) => ({
	container: (provided, state) => ({
		...provided
		// margin: '0 auto',
	}),
	option: (provided, state) => ({
		...provided,
		color: state.isSelected ? 'white' : 'inherit',
		textAlign: 'center',
		display: state.isSelected ? 'none' : '',
		background: 'rgba(0, 0, 0, 0);'
	}),
	control: () => ({
		display: 'flex',
		borderRadius: '0',
		position: 'relative',
		margin: '18px 0',

		// padding: '10px',

		width: '90px auto'
	}),
	valueContainer: (provided, state) => ({
		...provided,
		justifyContent: 'center'
	}),
	singleValue: (provided, state) => ({
		...provided,
		transition: 'opacity 300ms',
		color: 'white'
	}),
	indicatorSeparator: (provided) => ({ ...provided, opacity: 0 }),
	dropdownIndicator: (provided, state) => ({
		...provided,
		display: 'none',
		transform: state.selectProps.menuIsOpen ? 'rotateX(180deg)' : '',
		color: 'white',
		margin: '0px',
		padding: '0',
		transition: 'transform .2s ease-in-out'
	}),
	menu: (provided, state) => ({
		...provided,
		color: 'white',
		background: 'black',
		borderRadius: '0',
		margin: `${top ? '-260px' : '0'} 0 0 -20px`,
		width: '200px'
	})
})

class LangSelect extends Component {
	state = { option: this.props.menus[0] }

	onChange = async (option) => {
		await this.props.history.push(option.value)
	}

	render() {
		return (
			<Select
				styles={customStyles({ top: this.props.top })}
				defaultValue={this.props.menus[0]}
				value={this.props.menus[0]}
				onChange={this.onChange}
				options={this.props.menus}
				isSearchable={false}
			/>
		)
	}
}

export default withRouter(LangSelect)
