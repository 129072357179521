import React, { Component } from 'react'
import styled from 'styled-components'

import config from '../../../config'
import Image from '../Image'
import { GridItem } from './GridItem'

export default class ResponsiveLayout extends Component {
	render() {
		const { pos, smPos, img, smImg, margin, mdMargin, marginImg, imgPos, gap, smImgAlign, small } = this.props

		const col = {
			center: { first: '1/-1', second: '1/-1' },
			left: { first: '1/2', second: '2/3' },
			right: { first: '2/3', second: '1/2' },
			top: { first: '1/-1', second: '1/-1' },
			down: { first: '1/-1', second: '1/-1' }
		}

		const row = {
			center: { first: '1/-1', second: '1/-1' },
			left: { first: '1/-1', second: '1/-1' },
			right: { first: '1/-1', second: '1/-1' },
			top: { first: '1/2', second: '2/3' },
			down: { first: '2/3', second: '1/2' }
		}

		return (
			<WrapperSlide gap={gap} margin={margin} mdMargin={mdMargin}>
				<WrapperImg
					margin={marginImg}
					cols={col[pos].first}
					rows={row[pos].first}
					smCols={col[smPos].first}
					smRows={row[smPos].first}>
					<Image src={img} smSrc={smImg} imgPos={imgPos} smImgAlign={smImgAlign} small={small} />
				</WrapperImg>

				<WrapperBanner
					margin={marginImg}
					cols={col[pos].second}
					rows={row[pos].second}
					smCols={col[smPos].second}
					smRows={row[smPos].second}>
					{this.props.children}
				</WrapperBanner>
			</WrapperSlide>
		)
	}
}

const WrapperSlide = styled.div`
	display: grid;
	grid-gap: ${(props) => props.gap || 0};
	margin: ${(props) => props.margin || 0};
	min-width: 100%;
	max-height: 1024px;

	grid-template-rows: repeat(2, auto);
	grid-template-columns: repeat(2, auto);

	@media (max-width: ${config.size.md}px) {
		margin: ${(props) => props.mdMargin || 0};
	}
`

const WrapperBanner = styled(GridItem)`
	height: 100%;
	margin: 0 16px;
	@media (max-width: ${config.size.md}px) {
		margin: 0;
	}
`

const WrapperImg = styled(GridItem)`
	//height: min-content;
	
	//overflow: hidden;
`
