import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { th, en } from './locales'

const options = {
	interpolation: {
		escapeValue: false // not needed for react!!
	},

	debug: true,

	//   lng: 'en',

	resources: {
		th: {
			common: th.th
		},
		en: {
			common: en.en
		}
	},

	fallbackLng: 'en',

	ns: ['common'],

	defaultNS: 'common',

	react: {
		wait: false,
		bindI18n: 'languageChanged loaded',
		bindStore: 'added removed',
		nsMode: 'default'
	},

	detection: {
		// order and from where user language should be detected
		order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

		// keys or params to lookup language from
		lookupQuerystring: 'lng',
		lookupCookie: 'i18next',
		lookupLocalStorage: 'i18nextLng',
		lookupFromPathIndex: 0,
		lookupFromSubdomainIndex: 0,

		// cache user language on
		caches: ['localStorage', 'cookie'],
		excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

		// optional expire and domain for set cookie
		cookieMinutes: 10,
		cookieDomain: 'myDomain',

		// optional htmlTag with lang attribute, the default is:
		htmlTag: document.documentElement
	}
}

i18n.use(LanguageDetector).init(options)

export default i18n
