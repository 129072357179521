import styled, { createGlobalStyle } from 'styled-components'

import config from '../../../config'

const fontSize = {
	th: {
		desktop: { h1: '4.25rem', h2: '3rem', h3: '1.5rem', p: '1.5rem', small: '1.125rem' },
		mobile: { h1: '2.5rem', h2: '2rem', h3: '1.5rem', p: '1.5rem', small: '1.125rem' }
	},
	en: {
		desktop: { h1: '3rem', h2: '2rem', h3: '1rem', p: '1rem', small: '0.75rem' },
		mobile: { h1: '2rem', h2: '1.5rem', h3: '1rem', p: '1rem', small: '0.75rem' }
	}
}

export const TextStyle = createGlobalStyle`
	h1 {
		font-size: ${fontSize.en.desktop.h1};
		html:lang(th) & {
			font-size:  ${fontSize.th.desktop.h1};
			
		}

		@media (max-width: ${config.size.md}px) {
			font-size:  ${fontSize.en.mobile.h1};
			html:lang(th) & {
				font-size:  ${fontSize.th.mobile.h1};
				line-height: 2rem;
				}
			}

	}

	h2 {	
		font-size: ${fontSize.en.desktop.h2};
		html:lang(th) & {
			font-size: ${fontSize.th.desktop.h2};
		}

		@media (max-width: ${config.size.md}px) {
			font-size: ${fontSize.en.mobile.h2};
			html:lang(th) & {
				font-size: ${fontSize.th.mobile.h2};
			}
		}
	}
	
	h3 {
		font-size: ${fontSize.en.desktop.h3};
		html:lang(th) & {
			font-size: ${fontSize.th.desktop.h3};
		}

		@media (max-width: ${config.size.md}px) {
			font-size: ${fontSize.en.mobile.h3};
			html:lang(th) & {
				font-size: ${fontSize.th.mobile.h3};
			}
		}
	}

	p {
		font-size: ${fontSize.en.desktop.p};
		html:lang(th) & {
			font-size: ${fontSize.th.desktop.p};
		}

		@media (max-width: ${config.size.md}px) {
			font-size: ${fontSize.en.mobile.p};
			html:lang(th) & {
				font-size: ${fontSize.th.mobile.p};
				line-height: 1.5rem;
			}
		}
	}

	small {
		font-size: ${fontSize.en.desktop.small};
		html:lang(th) & {
			font-size: ${fontSize.th.desktop.small};
		}

		@media (max-width: ${config.size.md}px) {
			font-size: ${fontSize.en.mobile.small};
			html:lang(th) & {
				font-size: ${fontSize.th.mobile.small};
			}
		}
	}


`

export const H1 = styled.h1`
	color: ${(props) => props.color};
	margin: 0;
	font-weight: normal;
	text-align: ${(props) => props.align};

	@media (max-width: ${config.size.md}px) {
		color: ${(props) => props.smColor};
	}
`

export const H2 = styled.h2`
	color: ${(props) => props.color};
	margin: 0;
	font-weight: normal;
	text-align: ${(props) => props.align};

	@media (max-width: ${config.size.md}px) {
		color: ${(props) => props.smColor};
	}
`

export const H3 = styled.h3`
	color: ${(props) => props.color};
	margin: 0;
	font-weight: normal;

	@media (max-width: ${config.size.md}px) {
		color: ${(props) => props.smColor};
	}
`

export const P = styled.p`
	color: ${(props) => props.color};
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	font-weight: normal;
	@media (max-width: ${config.size.md}px) {
		color: ${(props) => props.smColor};
	}
`

export const SubText = styled.small`
	color: ${(props) => props.color};

	@media (max-width: ${config.size.md}px) {
		color: ${(props) => props.smColor};
	}
`
