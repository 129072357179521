import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const NavLinkWrapper = (props) => (
	<NavLink
		activeStyle={{
			opacity: '0.8'
		}}
		to={props.to}
		style={{ textDecoration: 'none' }}>
		<Wrapper> {props.children}</Wrapper>
	</NavLink>
)

const Wrapper = styled.div`
	color: #eeeeee;
	cursor: pointer;
	transition: all 0.2s ease-in;
	&:hover {
		/* transform: scale(1.05); */
		opacity: 0.8;
	}
`

export default NavLinkWrapper
