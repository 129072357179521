import develop from './develop'
import staging from './staging'
import production from './production'
import uat from './uat'

const { hostname } = window.location

const host = {
	localhost: develop,
	'cimb-preferred-dev.jibexchange.com': staging,
	'pgdwebuat01.cimbthai.com': uat,
	'preferred.cimbthai.com': production
}

let webConfig = host[hostname] || production

const common = {
	size: {
		md: 768,
		lg: 1024,
		sm: 425,
		xs: 320
	},
	color: {
		gradient: {
			red: `linear-gradient(40deg, rgb(85, 8, 8) 0%, rgba(204,0,10,1) 100%)`,
			lessRed: ` linear-gradient(45deg,#550606 25%, #ff000b 100%)`,
			gray: `linear-gradient(to right,#666666 0%,#AAAAAA 50%, #666666 100%)`
		}
	},
	download: {
		ios: 'https://itunes.apple.com/app/my-preferred/id1436964285',
		android: 'https://play.google.com/store/apps/details?id=com.cimbthai.mypreferred'
	}
}

const config = {
	...webConfig,
	...common
}

export default config
