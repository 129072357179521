import React from 'react'
import styled, { keyframes } from 'styled-components'

const DURATION = '0.5s'

const CrossButton = (props) => {
	return (
		<Cross isOpen={props.isOpen} onClick={props.toggle}>
			<First isOpen={props.isOpen} />
			<Second isOpen={props.isOpen} />
			<Third isOpen={props.isOpen} />
		</Cross>
	)
}

const original = 'transform: rotate(0deg) translateY(0px);'
const up = 'transform: rotate(-45deg)  translateY(-14px);'
const down = 'transform: rotate(45deg) translateY(14px);'

const rotateUp = keyframes`
from {
    ${original}
  }
  to {
    ${up}
  }
`

const rotateBackDown = keyframes`
from {
    ${up}
  }

  to {
    ${original}
  }
`

const rotateDown = keyframes`
from {
    ${original}
  }

  to { ${down} }
`

const rotateBackUp = keyframes`
from {
    ${down}
  }

  to {
    ${original}
  }
`

const fadeLeft = keyframes`
from {
	transform:translateX(0);
	opacity:1;
  }

  to {
	transform:translateX(-30px);
	opacity:0;
  }
`

const appearRight = keyframes`
from {
	transform:translateX(-30px);
	opacity:0;
  }

  to {
	transform:translateX(0);
	opacity:1;
  }
`

const Cross = styled.div`
	position: absolute;
	z-index: 1;
	right: 10px;
	top: 30%;
	width: 32px;
	height: 32px;
	opacity: 0.7;

	transition: all ${DURATION} ease-in-out;
	transform: translateX(${(props) => (props.isOpen ? 'calc(-100vw + 54px)' : '0')}) scale(0.7);
`
const Bar = styled.div`
	position: absolute;
	width: 32px;
	height: 2px;
	background-color: white;

	animation-duration: ${DURATION};
	animation-fill-mode: forwards;
`

const spacing = 10

const animate = (forward, backward) => ({ isOpen }) => (isOpen === undefined ? '' : isOpen ? forward : backward)

const First = styled(Bar)`
	animation-name: ${animate(rotateDown, rotateBackUp)};
`
const Second = styled(Bar)`
	top: ${spacing}px;
	animation-name: ${animate(fadeLeft, appearRight)};
`
const Third = styled(Bar)`
	top: ${spacing * 2}px;
	animation-name: ${animate(rotateUp, rotateBackDown)};
`

export default CrossButton
