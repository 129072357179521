import React from 'react'
import styled from 'styled-components'
import config from '../../config'

const Image = (props) => {
	let { src, alt, imgPos = 'center', smImgAlign = 'center', small } = props

	return <Img {...props} src={encodeURI(src)} alt={alt} imgPos={imgPos} smImgAlign={smImgAlign} small={small} />
}

const Img = styled.img`
	max-width: 100%;
	object-fit: cover;
	object-position: ${(props) => props.smImgAlign};

	@media (max-width: ${config.size.sm}px) {
		height: ${(props) => (props.small ? '200px' : '300px')};
	}
`

export default Image
