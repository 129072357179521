import React from 'react'
import styled from 'styled-components'

import Input from './Input'

const FieldInput = (props) => {
	return (
		<InputGroup>
			<label htmlFor={props.label}>{props.label}</label>
			<Input {...props} id={props.label} name={props.name} value={props.value} onChange={props.onChange} />
		</InputGroup>
	)
}

const InputGroup = styled.div`
	margin-bottom: 10px;
`

export default FieldInput
