import styled from 'styled-components'

export const GridItem = styled.div`
	grid-column: ${(props) => props.cols};
	grid-row: ${(props) => props.rows};
	margin: ${(props) => props.margin};

	@media (max-width: 768px) {
		grid-column: ${(props) => props.smCols};
		grid-row: ${(props) => props.smRows};
	}
`
