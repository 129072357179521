import React, { Component } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { pickBy, identity } from 'lodash'
import config from '../../config'

class CustomSlide extends Component {
	state = { active: 0 }

	setActive = (index) => this.setState({ active: index })

	render() {
		const {
			arrows,
			autoplay,
			lazyLoad,
			dots,
			infinite,
			slidesToShow = 1,
			centerPadding,
			centerMode,
			dotBottom,
			mdDotBottom,
			unset
		} = this.props
		let settings = {
			adaptiveHeight:true,
			arrows,
			autoplay,
			dots,
			infinite,
			speed: 2000,
			slidesToShow,
			slidesToScroll: 1,
			centerPadding,
			lazyLoad,
			centerMode,
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />,
			swipeToSlide: true,
			afterChange: this.setActive,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerMode: true
					}
				}
			],
			appendDots: (dots) => (
				<WrapperDot mdDotBottom={mdDotBottom} dotBottom={dotBottom}>
					{dots}
				</WrapperDot>
			),
			customPaging: (i) => <Dot onClick={() => this.setActive(i)} active={i === this.state.active} />
		}

		const cleanedSettings = { ...pickBy(settings, identity), arrows }

		if (unset) return this.props.children
		return (
			<Wrapper>
				<Slider pauseOnHover {...cleanedSettings}>
					{this.props.children}
				</Slider>
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`display: flex; flex-direction: column;`

const Arrow = styled.div`
	z-index: 1;
	opacity: 0.1;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	background: var(--red);
	transition: 0.3s opacity ease-in-out;

	&:hover {
		opacity: 1;
		background: var(--red);
	}

	&::before {
		content: '';
		transform: rotate(-45deg);
		border: solid white;
		border-width: 0 3px 3px 0;
		position: absolute;
		margin: 23px;
		padding: 5px;
	}

	@media (max-width: 768px) {
		display: none;
	}
`
const Next = styled(Arrow)`
	right: 10%;
`
const Prev = styled(Arrow)`
	left: 10%;
	&::before {
		transform: rotate(-225deg);
	}
`

function NextArrow(props) {
	const { className, onClick } = props
	return <Next className={className} onClick={onClick} />
}

function PrevArrow(props) {
	const { className, onClick } = props
	return <Prev className={className} onClick={onClick} />
}
const WrapperDot = styled.div`
  position: absolute;
	margin-bottom: ${(props) => props.dotBottom};
	white-space: nowrap;

	@media (max-width: ${config.size.md}px) {
		margin-bottom: ${(props) => props.mdDotBottom};
	}
`

const Dot = styled.div`
	border-radius: 10px;
	width: 10px;
	height: 10px;
	box-sizing: border-box;
	box-shadow: 1px 1px 1px 1px #ccc;
	background: #ffffff;
	transform-origin: 50%;
	transition: 0.3s ease-in-out;

	.slick-active & {
		background: #780000;
	}
`

export default CustomSlide
