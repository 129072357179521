import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { translate } from 'react-i18next'

import SideNavbar from './SideNavbar'
import config from '../../../config'

import bgNav from '../../../assets/images/bg-nav.svg'

const maxSize = '1200px'

class Navbar extends PureComponent {
	state = { scrollY: 0, slide: 0 }

	componentDidMount() {
		window.addEventListener('scroll', this.onScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll)
	}

	onScroll = (e) => {
		e.preventDefault()
		let currentScroll = window.scrollY
		if (currentScroll < 50) return null
		if (currentScroll > this.state.scrollY) {
			this.setState({ slide: '-68px' })
		} else {
			this.setState({ slide: '0px' })
		}
		this.setState({ scrollY: currentScroll })
	}

	render() {
		let { logo, language, children = [] } = this.props
		return (
			<Wrapper src={bgNav}>
				<InnerWrapper>
					<Logo>{logo}</Logo>

					<NavGroup>{children}</NavGroup>

					<Lang>{language}</Lang>

					<WrapperBurger>
						<SideNavbar>{[logo, language, ...children]}</SideNavbar>
					</WrapperBurger>
				</InnerWrapper>
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`
	display: grid;
	position: fixed;
	top: 0;
	z-index: 100;
	background: ${config.color.gradient.lessRed}, var(--red);
	height: 58px;
	width: 100%;
	justify-content: center;

	@media (max-width: ${maxSize}) {
		justify-content: start;
	}
`

const InnerWrapper = styled.div`
	display: flex;
	margin: 0 auto;
	min-width: 1160px;
	width: 100%;
	padding: 0 20px;

	@media (max-width: ${maxSize}) {
		min-width: 100%;
		margin: 0;
		padding:0 10px;
	}
`

const WrapperBurger = styled.div`
	display: none;

	@media (max-width: ${maxSize}) {
		display: block;
	}
`

const Logo = styled.div`
	justify-self: center;
	align-self: center;
	@media (max-width: ${maxSize}) {
		justify-self: start;
		/* padding-left: 10px; */
	}
`

const Lang = styled.div`
	cursor: default;
	justify-self: center;
	align-self: center;
	@media (max-width: ${maxSize}) {
		display: none;
	}
`

const NavGroup = styled.ul`
	display: flex;
	justify-content: center;

	box-sizing: border-box;
	margin: 0 auto;

	@media (max-width: ${maxSize}) {
		display: none;
	}
`
export default translate('common')(Navbar)
