import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import config from '../../../config'

import CrossButton from './CrossButton'

const DURATION = '0.5s'

class SideNavbar extends React.Component {
	state = {
		isOpen: undefined
	}

	componentWillReceiveProps(nextProps) {
		const isNotSameRoute = this.props.location.key !== nextProps.location.key
		if (isNotSameRoute) this.onClose()
	}
	onClose = () => {
		this.setState({ isOpen: false })
	}

	toggle = () => {
		this.setState({ isOpen: !this.state.isOpen })
	}

	render() {
		const { isOpen } = this.state
		return (
			<div>
				<CrossButton toggle={this.toggle} isOpen={isOpen} />
				<Wrapper isOpen={isOpen}>
					{this.props.children.map((item, index) => {
						return <MenuItem key={index}>{item}</MenuItem>
					})}
				</Wrapper>
			</div>
		)
	}
}

const Wrapper = styled.div`
	display: block;
	position: absolute;
	transition: transform ${DURATION} ease-in-out;
	transform: translateX(${(props) => (props.isOpen ? '0' : '100vw')});
	width: 100vw;
	height: 100vh;
	left: 0;

	border-left: 40px solid black;
	background: ${config.color.gradient.red};
`

const MenuItem = styled.div`
	list-style: none;
	padding: 15px 0;
	margin-left: 10px;

	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`

export default withRouter(SideNavbar)
