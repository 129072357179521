import React, { Component } from 'react'
import styled from 'styled-components'

import moment from 'moment'

import Keyword from './Keyword'
import format from '../../../utils/format'
import { toDate } from '../../../utils/time'
import i18next from 'i18next'

const cardWidth = '300px'

const RenderBadge = (props) => {
	const MAP = {
		special: {
			label: 'SPECIAL OF MONTH',
			color: `linear-gradient(90deg, rgba(121,8,11,1) 0%, rgba(196,35,42,1) 50%, rgba(121,8,11,1) 100%)`
		}
		// recommend: {
		// 	label: 'RECOMMENDED',
		// 	color: `linear-gradient(90deg, rgba(102,102,102,1)0%,rgba(170,170,170,1)50%,rgba(102,102,102,1) 100%)`
		// }
	}

	const { type } = props
	if (!MAP[type]) return null

	const { color, label } = MAP[type]

	return (
		<WrapperBadge>
			<BadgeStart/>
			<Badge color={color}>
				<TextBadge>{label}</TextBadge>
			</Badge>
			<BadgeEnd/>
		</WrapperBadge>
	)
}

const RenderExpire = ({ expired }) => {
	const showExpire = toDate(expired)

	if (!showExpire) return null

	return <Expire>{`EXPIRES: ${showExpire}`}</Expire>
}

export default class Card extends Component {
	constructor() {
		super()
		this.state = {
			language: i18next.language.split('-')[0]
		}
	}

	render() {
		const {
			bannerScr,
			logoSrc,
			category = '',
			name = '',
			type = '',
			value_text = '',
			point = 0,
			expired = moment()
		} = this.props

		const {language} = this.state

		return (
			<Wrapper>
				<RenderBadge type={type}/>
				<Banner src={bannerScr}/>
				<Logo src={logoSrc}/>
				<Content>
					<Keyword area="category" color="white" bg="var(--red)">
						{String(category).toUpperCase()}
					</Keyword>

					<Detail>
						<PrivName>{name}</PrivName>
						<Hilight>{value_text[language]}</Hilight>
					</Detail>

					<Point>
						<Hilight>{point ? `${format.toNumber(point)} PTS` : '-'}</Hilight>
					</Point>

					<RenderExpire expired={expired}/>

				</Content>
			</Wrapper>
		)
	}
}

const Hilight = styled.p`
	font-size: 22px;
	line-height: 22px;
	color: var(--red);
	white-space: nowrap;
	margin: 0;
`

const Logo = styled.img`
	z-index: 1;
	margin-bottom: -35px;
	margin-right: 10px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

	grid-area: overlap/content;
	display: block;
	background: white;
	height: 70px;

	align-self: center;
	justify-self: end;
	min-width: 70px;
	max-height: 70px;
	max-width: 140px;
`

const Content = styled.div`
	display: grid;
	padding: 10px;
	grid-row: content;
	background: white;
	grid-column: 1/-1;
	grid-template-columns: auto min-content;
	grid-template-rows: 25px auto;
	grid-template-areas:
		'category logo'
		'detail point'
		'expires expires';
`

const Wrapper = styled.div`
	/* centering center */
	margin: 20px auto;

	width: ${cardWidth};
	height: 360px;
	/* border-radius: 5px; */

	box-sizing: border-box;
	display: grid;
	grid-template-areas: 'banner' 'overlap' 'content';
	grid-template-columns: 1fr;
	grid-template-rows: minmax(130px, 0.4fr) min-content 1fr;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	/* border: 1px solid gray; */
`
const WrapperBadge = styled.div`
	z-index: 1;
	margin-right: -6px;
	margin-top: -6px;
	width: 130px;
	height: 130px;
	overflow: hidden;
	grid-area: banner;
	justify-self: end;
	align-self: start;
`

const TextBadge = styled.p`
	color: white;
	font-size: 0.8rem;
	font-weight: bold;
	text-align: center;

	margin-left: -19px;
	padding: 13px;
`

const Badge = styled.div`
	position: relative;
	margin-top: 24%;
	left: -10px;
	width: 200px;
	height: 40px;
	transform: rotate(45deg);
	background: rgb(102, 102, 102);
	background: ${(props) => props.color};
`

const BadgeStart = styled.div`
	z-index: -1;
	margin-left: 3px;
	margin-top: 1px;
	position: relative;
	width: 10px;
	height: 5px;
	transform: skewX(-45deg);
	background: #222222;
`
const BadgeEnd = styled.div`
	z-index: -1;
	position: relative;
	margin-left: 122px;
	margin-top: 41px;
	width: 5px;
	height: 10px;
	transform: skewY(-45deg);
	background: #222222;
`

const Banner = styled.div`
	grid-row: banner/overlap;
	height: 100%;
	max-width: 100%;
	max-width: ${cardWidth};
	grid-column: 1/-1;
	background-image: url(${(props) => encodeURI(props.src)});
	background-size: cover;
	background-position: center;
`

const PrivName = styled.p`
	font-size: 22px;
	color: #222222;
	overflow-wrap: break-word;

	/* width: 200px; */
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	margin: 0;
`

const Point = styled.div`
	margin: 20px 0 0 0;
	min-width: 70px;
	grid-area: detail/point;
	justify-content: center;
	text-align: center;
`

const Detail = styled.div`
	font-size: 18px;
	margin: 15px 0 0 0;
	grid-area: detail;
`

const Expire = styled.p`
	grid-area: expires;
	align-self: end;
	font-size: 12px;
	margin: 0;
	color: #888888;
	justify-self: start;
`
