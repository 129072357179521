import React from 'react'
import styled from 'styled-components'
import { translate } from 'react-i18next'

import Link from './Link'

const Footer = (props) => {
	return (
		<Wrapper>
			<WrapperNav>{props.children}</WrapperNav>

			<SeperatedLine />

			<WrapperCopyRight>
				<small style={{ marginBottom: '5px' }}>Copyright @ 2019 CIMB Group. All rights reserved.</small>
				<LinkWrapper>
					<Link to="/privacy">
						<Pipe>{props.t('message.footer.privacy')}</Pipe>
					</Link>
					<Pipe>|</Pipe>
					<Link to="/term-of-use">
						<Pipe>{props.t('message.footer.term')}</Pipe>
					</Link>
				</LinkWrapper>
			</WrapperCopyRight>
		</Wrapper>
	)
}

const Pipe = styled.small`
	margin: 0 5px;
	color: gray;
	cursor: pointer;
`

const LinkWrapper = styled.section`
	display: flex;
	justify-content: center;
	font-size: 12px;
`

const Wrapper = styled.div`
	background: linear-gradient(45deg, #222222 50%, rgba(32, 32, 32, 1) 100%);

	display: grid;
	grid-auto-rows: min-content;
	justify-items: center;
	align-items: center;
	width: 100%;
	height: 100%;
	color: gray;
`

const SeperatedLine = styled.hr`
	width: 100%;
	opacity: 0.5;
	border: 1px solid #707070;
`

const WrapperNav = styled.ul`
	display: grid;
	justify-items: content;
	grid-row-gap: 20px;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	padding-top: 30px;
	padding-bottom: 14px;
	grid-template-columns: repeat(5, auto);
	text-align: center;

	@media (max-width: 1200px) {
		grid-template-columns: auto;
	}
`

const WrapperCopyRight = styled.div`
	margin: 30px 20px;
	margin-top: 14px;
`

export default translate('common')(Footer)
