import React from 'react'
import styled from 'styled-components'

export default class SimpleDropdown extends React.Component {
	render() {
		return (
			<Dropdown>
				<span style={{ cursor: 'pointer' }}>{this.props.label}</span>
				<Content top={this.props.top}>{this.props.children}</Content>
			</Dropdown>
		)
	}
}

const Dropdown = styled.div`
	position: relative;
	display: inline-block;
`

const Content = styled.ul`
	display: none;
	position: absolute;

	background: black;
	min-width: 240px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	padding: 0 16px;
	z-index: 1;

	${(props) => props.top && 'bottom:20px;'}

	${Dropdown}:hover & {
		display: block;
	}
`
