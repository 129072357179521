import React, { Component } from 'react'
import Dropdown from './simple'
import Link from '../Link'

import styled from 'styled-components'

class Nav extends Component {
	render() {
		const { label, top, contents } = this.props
		const name = <Label color="white">{label}</Label>

		return (
			<Dropdown label={name} top={top}>
				{contents.map((item) => (
					<DropdownList key={item.value}>
						<Link to={item.value}>{item.label}</Link>
					</DropdownList>
				))}
			</Dropdown>
		)
	}
}

const Label = styled.span`
	display: flex;
	color: white;
	text-decoration: none;
`

const DropdownList = styled.li`
	list-style-type: none;
	color: white;
	width: 100%;
	padding: 0 10px;
	line-height: 60px;
	background: black;
	transition: all 0.5s cubic-bezier(1, 0.25, 0, 0.75) 0s;

	border-bottom: 1px solid gray;

	&:last-child {
		border: none;
	}
	&:hover {
		/* font-weight: bold; */
	}
`

export default Nav
