import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'

import './index.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'animate.css'
import App from './components'
import * as serviceWorker from './serviceWorker'

import i18n from './i18n'
import ScrollToTop from './ScrollToTop'
import { TextStyle } from './components/common/typo'

ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<TextStyle />
			<Router>
				<ScrollToTop>
					<App />
				</ScrollToTop>
			</Router>
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
