import React from 'react'
import styled from 'styled-components'

import { Link } from 'react-router-dom'

import { H1, H2, P } from '../typo'
import RedButton from '../RedButton'

const LocationCard = ({ image, name, detail, openingTime, contact, phoneNumber, mapLink }) => {
	return (
		<Card>
			<Image src={image} />
			<TextWrapper>
				<Name>{name}</Name>
				<Detail>{detail}</Detail>
				<Time>
					Opening Hours
					<br />
					Monday to Friday: {openingTime.mondayToFriday}
					<br />
					Saturday: {openingTime.saturday}
					<br />
					Sunday: {openingTime.sunday}
				</Time>
				<Contact>
					{contact} <br /> {phoneNumber}
				</Contact>
				<Link to={mapLink}>
					<RedButton>
						<H2>VIEW MAP</H2>
					</RedButton>
				</Link>
			</TextWrapper>
		</Card>
	)
}

const Card = styled.div`
	display: grid;
	grid-gap: 30px;
	grid-template-columns: 2fr 1fr;

	@media (max-width: 768px) {
		grid-template-columns: auto;
	}
`

const Image = styled.img`
	max-width: 100%;
`

const TextWrapper = styled.div`
	display: grid;
	grid-gap: 20px;
	grid-template-columns: auto;
	grid-auto-rows: min-content;
`

const Name = styled(H1)`
	padding: 0;
	margin: 0;
`

const Detail = styled(P)``

const Time = styled(P)``

const Contact = styled(P)``

export default LocationCard
