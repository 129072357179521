/** @format */
import moment from 'moment'

export const toDate = (val) => {
	if (!moment(val).isValid() || val === undefined) return null

	const formatDate = moment(val)
		.format('DD MMM YYYY')
		.toUpperCase()

	return formatDate
}
