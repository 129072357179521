/** @format */
import React from 'react'

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
		// alert(error,info)
		// logErrorToMyService(error, info)
	}

	render() {
		if (this.state.hasError) {
			this.setState({ hasError: false })
			console.log('Something went wrong')

			// return window.location.reload()
			// You can render any custom fallback UI
			return <h1>Something went wrong.</h1>
		}
		return this.props.children
	}
}
