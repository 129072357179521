const config = {
	// LOCAL (UNUSED)
	// api: 'http://192.168.1.102:10300'
	// api: 'http://localhost:10300'
	// api: 'http://192.168.43.102:10300',
	// STAGING (UNUSED)
	// api: 'https://cimb-preferred-api-dev.jibexchange.com',

	// LOCAL
	// api: 'http://localhost:3000',

	// STAGING
	// api: 'https://cimb-preferred-api.js-dev.cc',

	// PRODUCTION (for yarn build)
	api: 'https://preferred.cimbthai.com',

	reCaptchaSiteKey: '6Lcaf7UZAAAAADfGpEu7XHS1QxFNuN9gak1ppBLI'
}

export default config
