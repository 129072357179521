import http from '../utils/http'
import queryString from 'query-string'
import i18next from 'i18next'

const services = {
	getPage: async (props) => {
		if (!props.lang) props.lang = i18next.language.split('-')[0]

		let result = await http.get(`/cms/v1/content?${queryString.stringify(props)}`)
		return result
	},
	getContent: async (props) => {
		if (!props.lang) props.lang = i18next.language.split('-')[0]
		let result = await http.get(`/cms/v1/content?${queryString.stringify(props)}`)
		return result
	},
	getMenu: async (props) => {
		let result = await http.get(`/cms/menu`)
		if (result.status !== 200) return []
		return result.data.result
	},
	getCategory: async (props) => await http.get(`/cms/category?${queryString.stringify(props)}`)
}

export default services
