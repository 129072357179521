const config = {
	// api: 'http://localhost:10300',
	api: 'https://cimb-preferred-api-dev.jibexchange.com',
	// api: 'https://preferred.cimbthai.com'
	// api: 'https://preferred.cimbthai.com'
	// api: 'https://58.137.204.34',
	reCaptchaSiteKey: '6Lcaf7UZAAAAADfGpEu7XHS1QxFNuN9gak1ppBLI'
}

export default config
