import axios from 'axios'
import config from '../config'
axios.defaults.baseURL = config.api

axios.interceptors.response.use(null, (error) => {
	const expectedError = error.response && error.response.status >= 400 && error.response.status < 500

	if (!expectedError) {
		// logger.log(error);
		// toast.error("An unexpected error occurrred.");
	}
	return Promise.reject(error)
})

function setJwt(jwt) {
	axios.defaults.headers.common['Authorization'] = jwt
}

export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete,
	setJwt
}
