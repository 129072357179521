import React, { PureComponent } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

export class AnimateWrapper extends PureComponent {
	render() {
		return <TransitionGroup>{this.props.children}</TransitionGroup>
	}
}

export class AnimateItem extends PureComponent {
	render() {
		return (
			<TransitionGroup>
				<CSSTransition key={this.props.keys || ''} classNames="fade" timeout={500}>
					{this.props.children}
				</CSSTransition>
			</TransitionGroup>
		)
	}
}
