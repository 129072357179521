import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { H3 } from '../index'

class CategoryNav extends PureComponent {

	onClickCategory = (val) =>{
		this.props.onClickNav(val)

	}

	render() {
		const { options = [], selected, disLink, basePath = '' } = this.props

		return (
			<ResponsiveWrap>
				<WrapperNav>
					{options.map((item, index) => {
						const Item = (
							<CategoryItem key={index} onClick={() => this.onClickCategory(item.value)} active={selected === item.value}>
								<H3 color="black">{item.label}</H3>
								<SelectedLine active={selected === item.value}  />
							</CategoryItem>
						)

						if (disLink) return Item
						return (
							<Link replace to={{ pathname: `/${basePath}/${item.label}` }}>
								{Item}
							</Link>
						)
					})}
				</WrapperNav>

				<Underline />
			</ResponsiveWrap>
		)
	}
}

const Underline = styled.hr`
	margin: 0;
	opacity: 0.5;
`

const SelectedLine = styled.div`
	width: 100%;
	transform: scaleX(${(props) => (props.active ? 1 : 0)});
	height: 1px;
	background-color: rgba(121, 0, 8, 1);
	transition: all 500ms;
`

const ResponsiveWrap = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	width: 100%;

	margin: auto;
`

const WrapperNav = styled.div`
	display: flex;
	justify-content: center;

	overflow-x: auto;

	::-webkit-scrollbar {
		display: none;
	}
`

const CategoryItem = styled.div`
	padding: 0 15px;
	line-height: 24px;
	// border: solid ${({ active }) => (active ? 'rgba(121, 0, 8,1)' : '#CCC')};
	// border-width: 0 0 ${({ active }) => (active ? 2 : 1)}px 0;
	// border-width: 0 0 ${({ active }) => (active ? 2 : 1)}px 0;
	white-space: nowrap;
	user-select: none; 
`

export default CategoryNav
