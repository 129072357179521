import React, { Component } from 'react'
import styled from 'styled-components'

import { toDate } from '../../../utils/time'
import { Render } from '../../../utils/render'
import { SubText, P } from '../typo'

export default class NewsCard extends Component {
	render() {
		let { small, src, keyword, date, content, color = 'white', bg = 'black' } = this.props
		return (
			<Wrapper {...this.props}>
				<Picture small={small} src={src} />
				<WrapperContent>
					<Keyword area="tag" bg={bg} color={color}>
						{keyword}
					</Keyword>
					<Date>{toDate(date)}</Date>
					<Content>
						<Render html={content} />
					</Content>
				</WrapperContent>
			</Wrapper>
		)
	}
}

const Picture = styled.img`
		grid-area: picture;
		max-width: 100%;
		background-image: url(${(props) => encodeURI(props.src)});
		background-size: auto;
		background-position: center;
		background-repeat: no-repeat;`

const WrapperContent = styled.div`
	grid-area: content;
	padding: 15px 0;
	max-width: 100%;
	box-sizing: border-box;

	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-rows: min-content 1fr;
	grid-template-areas: 'tag date' 'text text';
`

const Keyword = styled(SubText)`
	grid-area: ${(props) => props.area};
	align-self: start;
	justify-self: start;

	text-align: center;
	min-width: 100px;
	margin: 0;
	padding: 3px;
	max-width: 120px;
	display: inline-block;

	color: ${(props) => props.color};
	background: ${(props) => props.bg};
`
const Date = styled(SubText)`
	grid-area: date;
	margin: 0;
	justify-self: end;
	align-self: start;
	opacity: 0.5;
	white-space: nowrap;
	padding: 3px;
`

const Content = styled(P)`
	margin-top: 10px;
	grid-area: text;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`

const Wrapper = styled.div`
  //padding:0 8px;
  max-width: 350px;
  margin:0 auto;
	display: grid;
	grid-template-rows: min-content 1fr;
	grid-template-columns: 1fr;
	grid-template-areas: 'picture' 'content';

	${(props) =>
		props.small &&
		`
    grid-template-rows: 1fr ;
    grid-template-columns:  1fr minmax(200px, 2fr);
    grid-template-areas: 'picture content';
  `}

	background: white;
`
