import styled from 'styled-components'

const Keyword = styled.p`
	grid-area: ${(props) => props.area};
	align-self: start;
	text-align: center;
	font-size: 8px;
	margin: 0;
	padding: 3px;
	/* min-width: 100px;*/
	max-width: 120px;
	border-radius: 2px;

	color: ${(props) => props.color};
	background: ${(props) => props.bg};

	html:lang(th) & {
			font-size:  14px;
			font-weight: bold;
		}
`

export default Keyword
