import React, { lazy, Suspense, Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import i18next from 'i18next'

import { AnimateItem } from './common/animation/SimpleAnimate'
import { Navbar, Footer, LangSelect, Link, ErrorBoundary } from './common'
import Dropdown from './common/dropdown/nav'
import config from '../config/index'
import cms from '../services/page'

import Logo from '../assets/images/cimb.svg'
import CIMB_LOGO from '../assets/images/cimb_logo.svg'

const LandingPage = lazy(() => import('./Landing'))
const PrivilegeList = lazy(() => import('./MyPreferred/Privilege/List'))
const PrivilegeDetail = lazy(() => import('./MyPreferred/Privilege/Detail'))
const EventList = lazy(() => import('./MyPreferred/Event/List'))
const EventDetail = lazy(() => import('./MyPreferred/Event/Detail'))
const WealthProductList = lazy(() => import('./MyPreferred/WealthProduct/List'))
const WealthProductDetail = lazy(() => import('./MyPreferred/WealthProduct/Detail'))
const DiscountList = lazy(() => import('./MyPreferred/Discount/List'))
const DiscountDetail = lazy(() => import('./MyPreferred/Discount/Detail'))
const FinancialList = lazy(() => import('./MyPreferred/Financial/List'))
const FinancialDetail = lazy(() => import('./MyPreferred/Financial/Detail'))

const RegionalBenefit = lazy(() => import('./MyPreferred/Regional'))
const LifeStyle = lazy(() => import('./MyPreferred/Lifestyle'))
const MyApp = lazy(() => import('./MyPreferred/Application'))

const NewsList = lazy(() => import('./PrefLifeNews/List'))
const NewsDetail = lazy(() => import('./PrefLifeNews/Detail'))

const ContactCenter = lazy(() => import('./ContactCenter'))

const PreferredDroplead = lazy(() => import('./Droplead/Preferred'))

const Privacy = lazy(() => import('./Privacy'))
const TermOfUse = lazy(() => import('./TermOfUse'))

class Main extends Component {
	state = {
		loading: true,
		transition: false,

		showMenu: []
	}

	componentDidMount() {
		window.addEventListener('load', this.onExit)
		document.querySelector('html').lang = i18next.language.split('-')[0]
		const me = this
		i18next.on('languageChanged', (lang) => {
			window.location.reload()
			me.setState({ loading: true })
			document.querySelector('html').lang = lang
		})

		this.getMenu()
	}

	getMenu = async () => {
		let result = await cms.getMenu()
		result = result.map((item) => item.name)

		this.setState({ showMenu: result })
	}

	onExit = async () => {
		await this.setState({ loading: false })
	}

	render() {
		const { showMenu } = this.state

		let menus = [
			{ label: 'Privileges', value: '/privilege', menu: 'privilege' },
			{ label: 'Event', value: '/event', menu: 'event' },
			{ label: 'Wealth Product', value: '/wealth-product', menu: 'wealth product' },
			{ label: 'Lifestyle Benefits', value: '/lifestyle', menu: 'lifestyle' },
			{
				label: 'Financial & Banking Benefits',
				value: '/financial/Financial%20&%20Banking%20Benefits',
				menu: 'financial'
			},
			{ label: 'Regional Benefits', value: '/financial/Regional%20Benefits', menu: 'regional' },
			{ label: 'Discount Benefits', value: '/discount', menu: 'discount' }
		]

		const menuFilter = menus.filter((item) => showMenu.includes(item.menu))

		const routes = [
			{ label: 'myPreferred Application', to: '/my-preferred-app', menu: 'myApplication' },
			{ label: `Who's Preferred`, to: '/droplead/preferred', menu: 'who is preferred(droplead)' },
			{ label: 'News & Activities', to: '/news-and-activities', menu: 'news' },
			{ label: `Contact Center`, to: '/contact-center', menu: 'contact' }
		]

		const routeFilter = routes.filter((item) => showMenu.includes(item.menu))

		const NavMenu = ({ top }) => [
			<NavItem key="0">
				<Dropdown top={top} label="Privileges" contents={menuFilter} />
			</NavItem>,
			...routeFilter.map((item) => (
				<NavItem key={item.label}>
					<Link to={item.to}>{item.label}</Link>
				</NavItem>
			))
		]

		const { location } = this.props
		const key = location.pathname.split('/')[1] // if key is difference will re-render

		return (
			<Layout>
				<ErrorBoundary>
					<Loading className={`animated`} loading={this.state.loading}>
						<img className={`animated infinite pulse`} width="64px" alt="" src={CIMB_LOGO} />
					</Loading>

					<Navbar logo={HomeLink} language={<LangSelect />}>
						{NavMenu({ top: false })}
					</Navbar>

					<div style={{ height: '56px', width: '100%' }} />

					<Suspense fallback={<div>Loading...</div>}>
						<div style={{ minHeight: '100%' }}>
							<AnimateItem keys={key}>
								<Switch location={location}>
									<Route exact path="/" component={LandingPage} />

									<Route path="/privilege/:category/:privilege" component={PrivilegeDetail} />
									<Route path="/privilege/:category" component={PrivilegeList} />
									<Route path="/privilege" component={PrivilegeList} />

									<Route path="/wealth-product/:category/:wealth-product" component={WealthProductDetail} />
									<Route path="/wealth-product/:category" component={WealthProductList} />
									<Route path="/wealth-product" component={WealthProductList} />

									<Route path="/discount/:category/:discount" component={DiscountDetail} />
									<Route path="/discount/:category" component={DiscountList} />
									<Route path="/discount" component={DiscountList} />

									<Route path="/financial/:category/:financial" component={FinancialDetail} />
									<Route path="/financial/:category" component={FinancialList} />
									<Route path="/financial" component={FinancialList} />

									<Route path="/news-and-activities/:category/:news" component={NewsDetail} />
									<Route path="/news-and-activities/:category" component={NewsList} />
									<Route path="/news-and-activities" component={NewsList} />

									<Route path="/event/:slug" component={EventDetail} />
									<Route path="/event/" component={EventList} />

									<Route path="/regional-benefits" component={RegionalBenefit} />
									<Route exact path="/lifestyle" component={LifeStyle} />
									<Route path="/my-preferred-app" component={MyApp} />
									<Route exact path="/contact-center" component={ContactCenter} />
									<Route path="/droplead/preferred" component={PreferredDroplead} />
									<Route path="/privacy" component={Privacy} />
									<Route path="/term-of-use" component={TermOfUse} />

									<Route component={LandingPage} />
								</Switch>
							</AnimateItem>
						</div>
					</Suspense>

					<Footer>{NavMenu({ top: true })}</Footer>
				</ErrorBoundary>
			</Layout>
		)
	}
}

const NavItem = styled.li`
	list-style-type: none;
	align-self: center;
	margin: 0 10px;
	white-space: nowrap;

	@media (max-width: 1200px) {
		margin: 0px;
	}
`

const Layout = styled.div`
	overflow: hidden;
`

const Loading = styled.div`
	display: ${(props) => (props.loading ? 'flex' : 'none')};
	background: var(--red);
	position: fixed;
	z-index: 1000;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	color: white;
`
const Image = styled.img`
	height: 20px;

	@media (max-width: ${config.size.xs}px) {
		height: 16px;
	}
`
const HomeLink = (
	<Link to="/">
		<Image src={Logo} />
	</Link>
)

export default withRouter(Main)
