import React, { Component } from 'react'
import Select from 'react-select'
import i18n from '../../../i18n'

const langOptions = [{ value: 'en', label: 'EN' }, { value: 'th', label: 'TH' }]

const customStyles = {
	option: (provided, state) => ({
		...provided,
		color: state.isSelected ? 'white' : 'inherit',
		textAlign: 'center',
		display: state.isSelected ? 'none' : '',
		background: 'rgba(0, 0, 0, 0);'
	}),
	control: () => ({
		display: 'flex',
		borderRadius: '0',
		position: 'static',
		padding: 0,
		cursor: 'pointer',

		width: '60px'
	}),
	singleValue: (provided, state) => ({
		...provided,
		transition: 'opacity 300ms',
		color: 'white',
		padding: 0,
		margin: 0
	}),
	indicatorSeparator: (provided) => ({ ...provided, opacity: 0 }),
	dropdownIndicator: (provided, state) => ({
		...provided,
		transform: state.selectProps.menuIsOpen ? 'rotateX(180deg)' : '',
		color: 'white',
		margin: '0px',
		padding: '0',
		transition: 'transform .2s ease-in-out'
	}),
	menu: (provided, state) => ({
		...provided,
		color: 'white',
		background: 'black',
		borderRadius: '0',
		margin: 10,
		width: 'auto'
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 0
	})
}
//

class LangSelect extends Component {
	state = { lang: langOptions.find((item) => item.value === i18n.language) }

	onChange = async (lang) => {
		await i18n.changeLanguage(lang.value)
		this.setState({ lang: lang })
	}

	render() {
		return (
			<Select
				styles={customStyles}
				defaultValue={langOptions[0]}
				value={this.state.lang}
				onChange={this.onChange}
				options={langOptions}
				isSearchable={false}
			/>
		)
	}
}

export default LangSelect
