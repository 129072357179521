import React from 'react'
import styled from 'styled-components'

import config from '../../config'
const { lessRed, gray } = config.color.gradient
const RedStyle = styled.button`
	display: grid;
	border: none;
	justify-items: center;
	align-items: center;
	height: 48px;
	line-height: 40px;
	text-align: center;

	width: ${({ width = '240px' }) => width};
	background: rgb(119, 3, 3);
	background: ${(props) => (props.gray ? gray : lessRed)};
	color: white;
	border-radius: 4px;
	cursor: pointer;

	transition: opacity 0.5s;

	&:disabled {
		opacity: 0.5;
	}
`

const RedButton = (props) => {
	return <RedStyle {...props}>{props.children}</RedStyle>
}

export default RedButton
