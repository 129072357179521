import styled from 'styled-components'

const Input = styled.input`
	padding: 10px;
	border: none;
	padding: 10px;
	line-height: 16px;
	height: 48px;
	background: #eeeeee;
	width: 100%;
	box-sizing: border-box;
`

export default Input
